export function getWindowScrollOffset() {
  var supportPageOffset = window.pageXOffset !== undefined;
  var isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
  var x = supportPageOffset ? window.pageXOffset : isCSS1Compat
      ? document.documentElement.scrollLeft
      : document.body.scrollLeft;
  var y = supportPageOffset ? window.pageYOffset : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop;
  return {
    x, y,
  };
}

export function getScrollTop() {
  var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
  if (document.body) {
    bodyScrollTop = document.body.scrollTop;
  }
  if (document.documentElement) {
    documentScrollTop = document.documentElement.scrollTop;
  }
  scrollTop = (bodyScrollTop - documentScrollTop > 0)
      ? bodyScrollTop
      : documentScrollTop;
  return scrollTop;
}

//文档的总高度
export function getScrollHeight() {
  var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
  if (document.body) {
    bodyScrollHeight = document.body.scrollHeight;
  }
  if (document.documentElement) {
    documentScrollHeight = document.documentElement.scrollHeight;
  }
  scrollHeight = (bodyScrollHeight - documentScrollHeight > 0)
      ? bodyScrollHeight
      : documentScrollHeight;
  return scrollHeight;
}

export function getWindowHeight() {
  var windowHeight = 0;
  if (document.compatMode == 'CSS1Compat') {
    windowHeight = document.documentElement.clientHeight;
  } else {
    windowHeight = document.body.clientHeight;
  }
  return windowHeight;
}

export const initQueryParams = params => {
  const defParams = {
    size: 10,
    current: 1,
    sort: 'id',
    order: 'descending',
    model: {},
    extra: {},
    timeRange: null,
  };
  return params ? {...defParams, ...params} : defParams;
};

