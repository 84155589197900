<template>
  <div>
    <Breadcrumb/>
    <section class="container">
      <FilterChannel @submit="searchList" @select="selectDataShow" :total="total"/>
    </section>
    <ChannelList :list="dataList" ref="channelList"/>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb";
import FilterChannel from "./components/FilterChannel";
import ChannelList from "./components/ChannelList";
import {getProductList} from '@/api/channel';

export default {
  name: "channel",
  components: {
    FilterChannel, // 过滤的组件
    Breadcrumb,  //
    ChannelList,  //渠道列表组件
  },
  data() {
    return {
      total: 0,
      regionGroups: {}, // 按地区分组
      countryGroups: {}, // 按国家地区分组
      typeGroups: {}, // 国包、经销商类型分组
      specificTypeGroups: {},
      dataList: []
    };
  },
  created() {
    this.getChannelList()
  },
  methods: {
    async searchList(value) {
      const {data: {data, isSuccess}} = await getProductList(value)
      if (isSuccess) {
        this.dataList = this.distinctCompany(data, 'Solution Box LLC')
      }
    },
    selectDataShow({type, data}) {
      if (type === 'country') {
        if (data.continent && data.country) {
          this.dataList = this.countryGroups[data.country]
          return false;
        }
        if (data.continent) {
          if ('Latin America' === data.continent) {
            this.dataList = this.distinctCompany(this.regionGroups[data.continent], 'Solution Box LLC')
          } else {
            this.dataList = this.regionGroups[data.continent]
          }
        }
      }
      if (type === 'partner') {
        if (data.partnerType && data.specificType) {
          if (data.specificType !== 'All') {
            this.dataList = this.distinctCompany(this.specificTypeGroups[data.partnerType][data.specificType], 'Solution Box LLC')
          } else {
            this.dataList = this.distinctCompany(this.typeGroups[data.partnerType], 'Solution Box LLC')
          }
          return false;
        }
      }
    },
    distinctCompany(arr = [], companyName = '') {
      let arr1 = arr.filter(i => i.companyFullName !== companyName)
      let arr2 = []
      for (let item of arr) {
        if (item.companyFullName === companyName) {
          arr2.push(item);
          break;
        }
      }
      return [...arr1, ...arr2]
    },
    async getChannelList() {
      const {data: {data, isSuccess}} = await getProductList()
      if (!isSuccess) {
        return false;
      }

      for (let item of data) {
        (this.regionGroups[item.region] || (this.regionGroups[item.region] = [])).push(item);
        (this.countryGroups[item.coopCountry] || (this.countryGroups[item.coopCountry] = [])).push(item);
        (this.typeGroups[item.partnerType] || (this.typeGroups[item.partnerType] = [])).push(item);
        
      }

      // 二级分类
      // 通过 typeGroups 进行二级分组
      for (let type in this.typeGroups) {
        if (!this.specificTypeGroups[type]) {
          this.specificTypeGroups[type] = {}
        }
        for (let item of this.typeGroups[type]) {
          if (item.specificType) {
            if (!this.specificTypeGroups[type][item.specificType]) {
              this.specificTypeGroups[type][item.specificType] = []
            }
            this.specificTypeGroups[type][item.specificType].push(item)
          }

          if (!this.specificTypeGroups[type]['Certified Service Partner']) {
            this.specificTypeGroups[type]['Certified Service Partner'] = []
          }
          !item.examStatus || this.specificTypeGroups[type]['Certified Service Partner'].push(item)
        }
      }

      this.selectDataShow({
        type: 'country',
        data: {
          continent: 'Africa',
          country: null
        }
      })
      // console.log(this.regionGroups)
      // console.log(this.countryGroups)
      // console.log(this.typeGroups)
      // console.log(this.specificTypeGroups)
    },
    
  }
}
</script>

<style scoped lang="less">
@media (min-width: 1200px) {
  .container {
    width: 1400px;
  }
}
</style>
