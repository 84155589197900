<template>
    <div class="section">
      <section class="container" v-loading="">
        <ul>
  
          <li v-for="(item,index) in list" :key="index">
            <div class="item-left">
              <img class="channel-image" :src="`${item.companyLogo}`" alt="">
            </div>
            <div class="item-right">
              <p class="item-right-p1">{{ item.companyShortName }}</p>
              <div class="type-box">
                <span v-if="item.specificType" class="partner-type">
                  {{ item.specificType }}
                </span>
                <span v-if="item.examStatus" class="partner-type">
                  {{ item.examStatus }}
                </span>
              </div>
              <hr>
              <p class="item-right-p2">{{$i18n.t('channel.website')}}:
                  <span v-on:click="jumpPage($event)" class="website">{{ item.webSite }}</span>
              </p>
              <p class="item-right-p3">{{ `${$i18n.t('channel.email')}：${item.email}` }}</p>
              <p class="item-right-p4">{{ `${$i18n.t('channel.phone')}：${item.phone}` }}</p>
              <p class="item-right-p5">{{ `${$i18n.t('channel.address')}：${item.address}` }}</p>
            </div>
          </li>
        </ul>
      </section>
    </div>
  </template>
  
  <script>
  import PullDownList from '../../../components/PullDownList';
  
  export default {
    name: 'ChannelList',
    components: {
      PullDownList
    },
    props: {
      list: []
    },
    data() {
      return {
        // channel: "hello world",
        loading: true,
        listData: [],
        newListData: [],
        allData: [],
        queryParam: {}
      };
    },
    watch: {

    },
    created() {
    //   this.getProductList()
    },
    methods: {
      //跳转到公司官网
      jumpPage(event){
        window.open(event.currentTarget.innerHTML)
      },
    },
  };
  </script>
  
  <style scoped lang="less">
  .section {
    border-top: 1px solid rgb(244, 248, 249);
    border-bottom: 1px solid rgb(244, 248, 249);
    background: rgb(247, 250, 251);
    padding: 20px 0;
    position: relative;
  
    .container {
      min-height: 400px;
  
      li {
        overflow: hidden;
        margin-bottom: 30px;
        padding: 26px 23px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
  
        .item-left {
          float: left;
          height: 234px;
          width: 234px;
          overflow: hidden;
          margin-right: 20px;
  
          img {
            width: 100%;
            height: 100%;
            opacity: 1;
            border-radius: 8px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(238, 238, 238, 1);
          }
        }
  
        .item-right {
          float: left;
  
  
          .item-right-p1 {
            font-size: 22px;
            margin: 0 0 13px 0;
          }
  
          hr {
            border: 1px solid rgba(238, 238, 238, 1)
          }
  
          p {
            white-space: pre;
          }
  
          .item-right-p2 {
            font-size: 16px;
            margin: 13px 0 0 0;
  
            a {
              color: #007aff;
            }
          }
          .type-box {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .partner-type+.partner-type {
              margin-left: 10px;
            }
          }
          .partner-type {
            border-radius: 8px;
            border: 1px solid rgba(199, 0, 11, 0.26);
            background: rgba(199, 0, 11, 0.03);
            padding: 5px 11px 5px 11px;
            width: auto;
            font-size: 16px;
            //width: auto;
            letter-spacing: 0px;
            color: rgba(199, 0, 11, 1);
            text-align: left;
            vertical-align: top;
            display: inline-block;
            margin-bottom: 12px;
          }
  
          .item-right-p3 {
            font-size: 16px;
            margin: 13px 0px;
          }
  
          .item-right-p4 {
            font-size: 16px;
            margin: 13px 0px;
          }
  
          .item-right-p5 {
            font-size: 16px;
            margin: 13px 0px;
          }
        }
      }
  
    }
  
    // &:after {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   height: 1000px;
    //   background-color: rgb(247, 250, 251);
    // }
  }
  
  .website{
    color: #0b61bf;
    cursor: pointer;
  }
  
  
  @media (min-width: 1200px) {
    .container {
      width: 1400px;
    }
  }
  
  </style>
  