<template>
  <div>
    <slot/>
    <el-empty v-if="total===0&&!loading" :image-size="180"/>
    <div v-show="loading" v-loading="loading" class="pull-down-loading"/>
  </div>
</template>

<script>
  import {debounce} from 'lodash';
  import {getScrollHeight, getScrollTop, getWindowHeight} from '../utils';

  export default {
    name: 'PullDownList',
    model: {
      prop: 'page',
      event: 'change',
    },
    props: {
      page: {
        type: Object,
        default() {
          return {
            size: 10,
            current: 1,
          };
        },
      },
      //触发加载的距离阈值，单位为px
      scrollDistance: {
        type: Number,
        default: 40,
      },
      total: {
        type: Number,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        scrollDisabled: false,
      };
    },
    created() {
      window.addEventListener('scroll', this.scrollMouse);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.scrollMouse);
    },
    methods: {
      scrollMouse: debounce(function(e) {
        if (this.disabled || this.scrollDisabled || this.loading) return;
        if (getScrollTop() + getWindowHeight() >= getScrollHeight() - this.scrollDistance) {
          let {size, current} = this.page;
          let total = this.total;
          if ((size * current) >= total) {
            this.scrollDisabled = true;
          } else {
            this.$set(this.page, 'current', ++current);
            this.$emit('load');
          }
        }
      }, 100, {'maxWait': 100, 'leading': false}),
    },
  };
</script>

<style scoped lang="less">
  .pull-down-loading {
    height: 80px;
    background-color: transparent;

    /deep/ .el-loading-mask {
      background-color: transparent;
    }
  }
</style>
